<template>
  <v-card
    class="mx-auto"
  >
    <v-card-text>
      <p class="text-h4 text--primary">
        RSS
      </p>
      <v-row
        v-if="item.AutoPlayFile"
        no-gutters
      >
        <v-col
          v-for="(rss, index) in item.AutoPlayFile.RSSs"
          :key="`rss-${item.ID}-${index}`"
          cols="12"
          sm="12"
          md="12"
        >
          <v-card
            class="pa-2"
            outlined
            tile
          >
            <v-row
              no-gutters
            >
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                {{rss.name}}
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                {{rss.url}}
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
}
</script>

